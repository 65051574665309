import React from 'react';
import ReactDOM from 'react-dom/client';
import Routers from "./routers";
import {
    closePageBehaviorRecord, buildStayTimeParams,
    saveTextToFile, behaviorRecord,
    getHashValueByHistory
} from './util';
import { IS_VALID_PROSPECT_ID } from './constant';

ReactDOM.createRoot(document.getElementById("root"))
    .render(<Routers />);

window._indexStayTime_ = 0;
window._submitStatyTime_ = 0;

const beforeunloadHandler = () => {
    const indexParams = {
        customerId: getHashValueByHistory(),
        sourceType: (document.referrer && "TS") || '',
        sourceId: document.referrer || '',
        id: sessionStorage.getItem('stayTimeId'),
        indexPageDwellTime: _indexStayTime_
    };

    const submitParams = {
        customerId: getHashValueByHistory(),
        sourceType: (document.referrer && "TS") || '',
        sourceId: document.referrer || '',
        id: sessionStorage.getItem('stayTimeId'),
        submitPageDwellTime: _submitStatyTime_
    };

    if (IS_VALID_PROSPECT_ID) {
        if (document.referrer) {
            if (document.referrer.indexOf(process.env.REACT_APP_DOMAIN_NAME) !== -1) {
                if (window.location.search != '' && window.location.search != null) {
                    const searchVal = getHashValueByHistory();
                    if (searchVal.length == 18) {
                        if (document && document.location
                            && document.location.href.indexOf('index.html') !== -1) {
                            closePageBehaviorRecord(indexParams);
                        } else if (document && document.location
                            && document.location.href.indexOf('submit.html') !== -1) {
                            closePageBehaviorRecord(submitParams);
                        };
                    }
                }
            };
        } else {
            if (window.location.search != '' && window.location.search != null) {
                const searchVal = getHashValueByHistory();
                if (searchVal.length == 18) {
                    if (document && document.location
                        && document.location.href.indexOf('index.html') !== -1) {
                        closePageBehaviorRecord(indexParams);
                    } else if (document && document.location
                        && document.location.href.indexOf('submit.html') !== -1) {
                        closePageBehaviorRecord(submitParams);
                    };
                }
            }
        }
    } else {
        if (document && document.location
            && document.location.href.indexOf('index.html') !== -1) {
            closePageBehaviorRecord(indexParams);
        } else if (document && document.location
            && document.location.href.indexOf('submit.html') !== -1) {
            closePageBehaviorRecord(submitParams);
        };
    }
};

window.addEventListener('beforeunload', beforeunloadHandler);

if (module.hot) {
    module.hot.accept();
};