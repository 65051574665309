import React, { useRef, useState } from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';
import ValidFailedError from '../../components/validFailedError'
import {
    REQUIRED_ERROR_MSG, EMAIL_ERROR_MSG, ERROR_MSG_LIST,
    NUMBER_LETTER_REG, NUMBER_LETTER_MSG
} from '../../constant';
import { behaviorRecord, buildStayTimeParams } from '../../util';

export default function Input(props) {
    const { forLabel, label, rule,
        type = 'text', refValue, showError,
        setShowError, isFormFilling,
        setIsFormFilling, staySubmitTime } = props;
    const [errorLabel, setErrorLabel] = useState(REQUIRED_ERROR_MSG);
    const validRequiredVal = (e) => {
        const val = e && e.target && e.target.value;
        if (!val) {
            setShowError(true);
            setErrorLabel(REQUIRED_ERROR_MSG);
        } else {
            if (val) {
                let specialCharactNum = 0;
                let val_list = val.split('');
                for (let i = 0; i < val_list.length; i++) {
                    if (!NUMBER_LETTER_REG.test(val_list[i])) {
                        specialCharactNum = specialCharactNum + 1;
                    };
                };
                if (forLabel != 'emailAddress' && specialCharactNum > 3) {
                    setShowError(true);
                    setErrorLabel(NUMBER_LETTER_MSG);
                    return;
                } else {
                    setShowError(false);
                }
            };

            if (rule && rule['valid'] && rule['valid']['reg']
                && !rule['valid']['reg'].test(val)) {
                setShowError(true);
                setErrorLabel(rule['valid']['errorMsg']);
            } else {
                setShowError(false);
            };
        }

        if (!isFormFilling) {
            const params = {
                ...buildStayTimeParams('inputSubmitParams'),
                id: sessionStorage.getItem('stayTimeId'),
                submitPageDwellTime: staySubmitTime == 0 ? 1 : staySubmitTime
            };
            behaviorRecord(params);
        };
        setIsFormFilling(true);
    }

    const validCompanyRequiredVal = (e) => {
        const val = e && e.target && e.target.value;
        if (val) {
            let specialCharactNum = 0;
            let val_list = val.split('');
            for (let i = 0; i < val_list.length; i++) {
                if (!NUMBER_LETTER_REG.test(val_list[i])) {
                    specialCharactNum = specialCharactNum + 1;
                };
            };
            if (specialCharactNum > 3) {
                setShowError(true);
                setErrorLabel(NUMBER_LETTER_MSG);
                return;
            } else {
                setShowError(false);
            }
        };

        if (!isFormFilling) {
            const params = {
                ...buildStayTimeParams('submitedParams'),
                id: sessionStorage.getItem('stayTimeId'),
                submitPageDwellTime: staySubmitTime == 0 ? 1 : staySubmitTime
            };
            behaviorRecord(params);
        };
        setIsFormFilling(true);
    }

    return (
        <div className="c-input">
            {
                rule['required'] ?
                    <>
                        <label htmlFor={forLabel}>{label}</label>
                        <input id={forLabel} type={type} name={forLabel}
                            ref={refValue}
                            className={`input form-input ${showError ? 'error-input' : ''}`}
                            onBlur={validRequiredVal} />
                        {showError ? <ValidFailedError errorMsg={errorLabel}></ValidFailedError> : null}
                    </> :
                    <>
                        <label htmlFor={forLabel}>{label}</label>
                        <input id={forLabel} type={type} name={forLabel} ref={refValue}
                            onBlur={validCompanyRequiredVal}
                            className="input form-input" />
                        {showError ? <ValidFailedError errorMsg={errorLabel}>
                        </ValidFailedError> : null}
                    </>
            }
        </div>
    );
}
