import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';
import Cash_title_submit from '../../assets/tradeShift_cash_title_submit.svg';
import Input from '../../components/input';
import Select from '../../components/select';
import MultiSelect from '../../components/multiSelect';
import top_arrow from '../../assets/top_arrow.svg';
import error_svg from '../../assets/message/error.svg';
import share from '../../assets/share.svg';
import submit_check from '../../assets/submit_check.svg';
import {
	TITLE_LIST, EMAIL_ERROR_MSG, PHONE_NUMBER_ERROR_MSG, PLEASE_SELECT,
	PHONE_REG, EMAIL_REG, BACK_TIME_LIST, IS_VALID_PROSPECT_ID,
	IS_SET_BG_PURPLE
} from '../../constant';
import axios from 'axios';
import {
	getUKTime, deleteMeta, getHashValueByHistory,
	getUUID, behaviorRecord, buildStayTimeParams
} from '../../util';
import { Base64 } from 'js-base64';

export default function topSection() {
	const navigate = useNavigate();

	const [titleRef, setTitle] = useState('Please select');
	const [privacyNoticeStatus, setPrivacyNoticeStatus] = useState(false);
	const [validPrivacyNoticeStatus, setValidPrivacyNoticeStatus] = useState(false);
	const [isDisabledBtn, setIsDisabledBtn] = useState(false);
	const [isApiError, setIsApiError] = useState(false);
	const [isFormFilling, setIsFormFilling] = useState(false);
	const firstNameRef = useRef('');
	const lastNameRef = useRef('');
	const emailAddressRef = useRef('');
	const phoneNumberRef = useRef('');
	const companyRef = useRef('');
	let timer = null;

	const [showTitleError, setShowTitleError] = useState(false);
	const [showFirstNameError, setShowFirstNameError] = useState(false);
	const [showLastNameError, setShowLastNameError] = useState(false);
	const [showEmailAddressError, setShowEmailAddressError] = useState(false);
	const [showPhoneNumberError, setShowPhoneNumberError] = useState(false);
	const [showCompanyError, setShowCompanyError] = useState(false);
	const [showBackTimeError, setShowBackTimeError] = useState(false);
	const [apiTimer, setApiTimer] = useState('');
	const [isOpenLink, setIsOpenLink] = useState(false);

	const storage = window.sessionStorage;

	const getStorage = () => {
		storage.getItem('staySubmitTime');
	};

	const setStorage = () => {
		storage.setItem('staySubmitTime', staySubmitTime);
	};
	const [staySubmitTime, setStaySubmitTime] = useState(getStorage() || 0);

	const checkPrivacyNotice = () => {
		setIsOpenLink(true);
		storage.setItem('isOpenLink', true);
		window.open('https://www.business.hsbc.com/privacy-policy', '_blank');
	}

	const backToTop = () => {
		window.scroll(0, 0);
	}

	const validPage = () => {
		let isPassValid = true;
		const title = titleRef;
		const firstName = firstNameRef.current.value;
		const lastName = lastNameRef.current.value;
		const emailAddress = emailAddressRef.current.value;
		const phoneNumber = phoneNumberRef.current.value;
		const company = companyRef.current.value;
		const backTime = document.getElementById('timeInput').value;

		if (title === PLEASE_SELECT) {
			setShowTitleError(true);
			isPassValid = false;
		}

		if (!firstName) {
			setShowFirstNameError(true);
			isPassValid = false;
		}

		if (!lastName) {
			setShowLastNameError(true);
			isPassValid = false;
		}

		if (!emailAddress || !EMAIL_REG.test(emailAddress)) {
			setShowEmailAddressError(true);
			isPassValid = false;
		}

		if (!phoneNumber || !PHONE_REG.test(phoneNumber)) {
			setShowPhoneNumberError(true);
			isPassValid = false;
		}

		if (backTime === PLEASE_SELECT) {
			setShowBackTimeError(true);
			isPassValid = false;
		}

		if (!privacyNoticeStatus) {
			setValidPrivacyNoticeStatus(true);
			isPassValid = false;
		}

		return isPassValid;
	}

	const buildParams = () => {
		const title = titleRef;
		const firstName = firstNameRef.current.value;
		const lastName = lastNameRef.current.value;
		const emailAddress = emailAddressRef.current.value;
		const phoneNumber = phoneNumberRef.current.value;
		const company = companyRef.current.value;
		const backTime = BACK_TIME_LIST[document.getElementById('timeInput').value];
		const searchVal = getHashValueByHistory();

		const params = {
			'behaviorRecordId': sessionStorage.getItem('stayTimeId') || '',
			'customerId': searchVal,
			'title': title,
			'firstName': firstName,
			'lastName': lastName,
			'emailAddress': Base64.encode(emailAddress),
			'contactPhoneNumber': phoneNumber,
			'registeredCompanyName': company,
			'preferredCallBackTime': backTime,
			'submitTime': getUKTime()
		};

		return params;
	}

	const buildApiBody = () => {
		const params = buildParams();
		const headers = {
			headers: {
				'Content-Type': 'application/json',
				'X-HSBC-Request-Correlation-Id': getUUID(),
				'X-HSBC-CountryCode': 'HK'
			}
		};

		return {
			params,
			headers
		}
	}

	const clear = () => {
		setIsApiError(true);
		setIsDisabledBtn(false);
		timer = setTimeout(() => {
			setIsApiError(false);
			clearTimeout(timer);
		}, 6000);
		setApiTimer(timer);
	}

	const callApi = async () => {
		try {
			setIsDisabledBtn(true);
			clearTimeout(apiTimer);
			const { params, headers } = buildApiBody();
			const url = process.env.REACT_APP_API_URL;
			const res = await axios.post(url, params, headers).catch(err => {
				clear();
				console.log(err);
			});
			if (!res) {
				clear();
				return;
			};
			const { code } = res && res.data;
			if (code === 'EMKT200') {
				setIsDisabledBtn(false);
				const searchVal = getHashValueByHistory();
				let p = {
					...buildStayTimeParams('submitedParams'),
					submitPageDwellTime: (staySubmitTime == 0 ? 1 : staySubmitTime),
					id: sessionStorage.getItem('stayTimeId'),
					submitFormFlag: 'Y',
					formFillingTag: 'Y'
				};
				navigate(`/uk/success.html${window.location.search}`, { replace: true });
				behaviorRecord(p, clear);
			} else {
				let p = {
					...buildStayTimeParams('submitedParams'),
					submitPageDwellTime: (staySubmitTime == 0 ? 1 : staySubmitTime),
					id: sessionStorage.getItem('stayTimeId'),
					submitFormFlag: 'Y',
					formFillingTag: 'Y'
				};
				behaviorRecord(p, clear);
				clear();
			};
		} catch (error) {
			clear();
			let p = {
				...buildStayTimeParams('submitedParams'),
				submitPageDwellTime: (staySubmitTime == 0 ? 1 : staySubmitTime),
				id: sessionStorage.getItem('stayTimeId'),
				submitFormFlag: 'Y',
				formFillingTag: 'Y'
			};
			behaviorRecord(p);
		};
	}

	const submit = (e) => {
		if (e !== 'click' && e.keyCode !== 13) {
			return;
		};

		if (!validPage()) {
			return;
		};

		callApi();
	}

	const backToHome = () => {
		const searchVal = getHashValueByHistory();
		let time = (staySubmitTime == 0 ? 1 : staySubmitTime);
		let params = {
			id: sessionStorage.getItem('stayTimeId'),
			customerId: getHashValueByHistory(),
			sourceType: (document.referrer && "TS") || '',
			submitPageDwellTime: time,
			submitFormFlag: 'N',
			formFillingTag: isFormFilling ? 'Y' : 'N'
		};
		behaviorRecord(params);
		if (searchVal) {
			navigate(`/uk/index.html${window.location.search}`, { replace: false });
		} else {
			navigate(`/uk/index.html`, { replace: false });
		};
	};

	useLayoutEffect(() => {
		if (IS_VALID_PROSPECT_ID) {
			sessionStorage.setItem('referrer', document.referrer);
			if (document.referrer) {
				if (document.referrer.indexOf(process.env.REACT_APP_DOMAIN_NAME) === -1) {
					document.getElementById('submit-page-root').style.display = 'none';
					return;
				};
			};

			if (window.location.search != '' && window.location.search != null) {
				const searchVal = getHashValueByHistory();
				if (searchVal.length == 18) {
					document.getElementById('submit-page-root').style.display = 'block';
				} else {
					document.getElementById('submit-page-root').style.display = 'none';
				}
			} else {
				document.getElementById('submit-page-root').style.display = 'none';
			};
		} else {
			document.getElementById('submit-page-root').style.display = 'block';
		};

		window.scroll(0, 0);
		let timeId = setInterval(() => {
			setStaySubmitTime((Time) => Time + 1);
			_submitStatyTime_ = _submitStatyTime_ + 1;
		}, 1000);

		return () => {
			clearInterval(timeId);
			timeId = null;
			_submitStatyTime_ = 0;
			setStorage();
		};

	}, []);

	return (
		<div id="submit-page-root" style={{ display: 'none' }}>
			<div className="tt-header">
				<div className="header-sed">
					<div className="t-icon"></div><span>HSBC</span>
					<button className={`${IS_SET_BG_PURPLE ? 'ttr-btn-blue' : 'success-find-out-more button-header'}`}
						onClick={backToHome}>Find out more</button>
				</div>
			</div>
			<div className="ttr-submit-container">
				<div className="submit-h">
					<div className="t-submit">
						<div className="t-top">
							<img src={Cash_title_submit} alt="" />
							<h1 className="t-call">Request a call to learn more</h1>
							<span className="Please-enter">Please enter your details in the form
								below and we will call you back when it suits you. </span>
						</div>
						<div className="t-section">
							<Select
								forLabel={'titleInput'}
								label={'Title *'}
								refValue={titleRef}
								setRef={setTitle}
								isSelect={true}
								showError={showTitleError}
								setShowError={setShowTitleError}
								staySubmitTime={staySubmitTime}
								isFormFilling={isFormFilling}
								setIsFormFilling={setIsFormFilling}
								options={TITLE_LIST}>
							</Select>
							<Input
								forLabel={'firstName'}
								label={'First name *'}
								showError={showFirstNameError}
								setShowError={setShowFirstNameError}
								staySubmitTime={staySubmitTime}
								isFormFilling={isFormFilling}
								setIsFormFilling={setIsFormFilling}
								rule={{
									required: true
								}}
								refValue={firstNameRef}>
							</Input>
							<Input
								forLabel={'lastName'}
								label={'Last name *'}
								showError={showLastNameError}
								setShowError={setShowLastNameError}
								staySubmitTime={staySubmitTime}
								isFormFilling={isFormFilling}
								setIsFormFilling={setIsFormFilling}
								rule={{
									required: true
								}}
								refValue={lastNameRef}>
							</Input>
							<Input
								forLabel={'emailAddress'}
								label={'Email address *'}
								showError={showEmailAddressError}
								setShowError={setShowEmailAddressError}
								staySubmitTime={staySubmitTime}
								isFormFilling={isFormFilling}
								setIsFormFilling={setIsFormFilling}
								type={'email'}
								rule={{
									required: true,
									valid: {
										reg: EMAIL_REG,
										errorMsg: EMAIL_ERROR_MSG
									}
								}}
								refValue={emailAddressRef}>
							</Input>
							<Input
								forLabel={'phoneNumber'}
								label={'Contact phone number *'}
								showError={showPhoneNumberError}
								setShowError={setShowPhoneNumberError}
								staySubmitTime={staySubmitTime}
								isFormFilling={isFormFilling}
								setIsFormFilling={setIsFormFilling}
								rule={{
									required: true,
									valid: {
										reg: PHONE_REG,
										errorMsg: PHONE_NUMBER_ERROR_MSG
									}
								}}
								refValue={phoneNumberRef}>
							</Input>
							<Input
								forLabel={'company'}
								label={'Registered company name'}
								refValue={companyRef}
								showError={showCompanyError}
								setShowError={setShowCompanyError}
								staySubmitTime={staySubmitTime}
								isFormFilling={isFormFilling}
								setIsFormFilling={setIsFormFilling}
								rule={{
									required: false
								}}
							>
							</Input>
							<MultiSelect
								showError={showBackTimeError}
								setShowError={setShowBackTimeError}
								staySubmitTime={staySubmitTime}
								isFormFilling={isFormFilling}
								setIsFormFilling={setIsFormFilling}
							>
							</MultiSelect>
							<div className="t-checbox">
								<a href='/#' htmlFor="checkbox" className="last_checkbox"
									aria-label="setPrivacyNoticeStatus"
									onClick={(e) => { e.preventDefault(); setPrivacyNoticeStatus(!privacyNoticeStatus) }}
									onBlur={(e) => { e.preventDefault(); setValidPrivacyNoticeStatus(!privacyNoticeStatus) }}>
									<span className={`f-checkbox ${validPrivacyNoticeStatus ? 'privacyNoticeValid' : ''}`}>
										{privacyNoticeStatus ? <span className="check check_submit">
											<img src={submit_check} alt="" className="check-img" />
										</span> : null}
									</span>
								</a>
								<div>
									<label className="text" onClick={() => {
										setPrivacyNoticeStatus(!privacyNoticeStatus);
										setValidPrivacyNoticeStatus(privacyNoticeStatus)
									}}>By submitting this form, we will process your personal
										data to contact you
										about Global Trade Solutions related products and information you have requested.
										Find out
										more on how we'll use your personal information and your rights by downloading our
										Privacy
										Notice.</label>
								</div>
							</div>
							<div>
								<button className='ttr-btn-blue'
									onClick={checkPrivacyNotice}
									id="privacy_download_pdf">
									<img src={share} className="out-link" alt="" />
									Check our Privacy Notice
								</button>
							</div>
							<div className="submit-div">
								<button id='submit' type="button" className={`ttr-btn-blue
									${isDisabledBtn ? 'submit-btn-disabled' : ''}`}
									onKeyDown={(e) => { submit(e) }}
									onClick={() => submit('click')} disabled={isDisabledBtn}>Submit</button>
								{isDisabledBtn ? <div className="btn-loading"></div> : null}
							</div>
							{isApiError ? <div className="network-error">
								<img src={error_svg} alt="" />
								<span> Network abnormality, please try again later.</span>
							</div> : null}
						</div>
					</div>
				</div>
				<div className="submit-back-to-top">
					<button className="submit-back-btn" onKeyDown={backToTop}>
						<a className="submit-circle" title="Back to top" onClick={backToTop}>
							<img src={top_arrow} alt="" />
						</a>
						<a className="text" onClick={backToTop}>Back to top</a>
					</button>
				</div>
			</div>
		</div>
	);
}
