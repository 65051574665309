export const IS_VALID_PROSPECT_ID = false;
export const REACT_APP_DOMAIN_NAME = 'go.tradeshift.com';
export const IS_SET_BG_PURPLE = true;
export const EMAIL_SUBJECT = "Call back request from Tradeshift Customer";
export const TITLE_LIST = ['Please select', 'Mr', 'Mrs', 'Miss', 'Ms'];

export const BACK_TIME_LIST = {
    'Morning': 'Morning',
    'Afternoon': 'Afternoon',
    'Morning;Afternoon': `Morning;Afternoon`
};


export const REQUIRED_ERROR_MSG = 'This field is required';
export const REQUIRED_SELECT_ERROR_MSG = 'Please select an item in the list';
export const EMAIL_ERROR_MSG = 'Please enter a valid Email address.';
export const PHONE_NUMBER_ERROR_MSG = 'Please enter a valid contract phone number.';
export const PLEASE_SELECT = 'Please select';
export const NUMBER_LETTER_MSG = 'Please enter no more than 3 special characters';

export const ERROR_MSG_LIST = {
    required: REQUIRED_ERROR_MSG,
    selectRequired: REQUIRED_SELECT_ERROR_MSG,
    emailErrorMsg: EMAIL_ERROR_MSG,
    phoneErrorMsg: PHONE_NUMBER_ERROR_MSG
};

export const EMAIL_REG = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const PHONE_REG = /^\+?\d+$/;
export const NUMBER_LETTER_REG = /^[A-Za-z0-9]+$/;

export const INFORMATION_LIST = [
    {
        title: 'Legal Information and Privacy Notice',
        link: 'https://www.business.hsbc.com/privacy-policy',
        className: 'Legal'
    },
    {
        title: 'Cookie Notice',
        link: 'https://www.business.hsbc.com/cookie-policy#Cookie-Notice',
        className: 'cookie cookie-policy'
    },
    {
        title: 'Website Terms and Conditions',
        link: 'https://www.business.hsbc.com/terms-and-conditions',
        className: 'Website'
    }
];
