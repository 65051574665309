import { IS_VALID_PROSPECT_ID } from '../constant';
import axios from 'axios';

export const formatDateTime = (milliseconds) => {
    const date = new Date(milliseconds);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');

    return `${year}${month}${day} ${hours}:${minutes}:${seconds}`;
};

export const getUKTime = (millisecond) => {
    let date = new Date();
    if (millisecond) {
        date = new Date(millisecond);
    };
    const formatter = date.toLocaleString('en-US', { timeZone: 'Europe/London' });
    return formatter.replace(',', '');
};

export const loadMeta = () => {
    var meta = document.createElement('meta');
    meta.setAttribute('name', 'robots');
    meta.setAttribute('content', 'noindex,nofollow');
    document.head.appendChild(meta);
};

export const deleteMeta = () => {
    var meta = document.querySelector('meta[name="robots"]');
    if (meta) {
        meta.parentNode.removeChild(meta);
    };
};

export const getHashValueByHistory = () => {
    var searchVal = '';
    var flag = true;
    if (window.location.search.startsWith('?n=')) {
        var params = [], urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.forEach(function (value, key) {
            if (key == 'n' && flag) {
                searchVal = value
                flag = false;
            } else {
                params.push(
                    {
                        'key': key,
                        'value': value
                    }
                )
            }
        });
        for (let i = 0; i < params.length; i++) {
            if (!params[i]['value']) {
                searchVal += "&" + params[i]['key'];
            }
        }
    }
    return searchVal;
}

export const getHashValueByHistory1 = () => {
    let value = '';
    if (window.location && window.location.search
        && window.location.search.indexOf('?') !== -1) {
        const searchVal = window.location.search.slice(
            window.location.search.indexOf('?') + 1);
        value = searchVal.split('=')[1]
    };
    return value;
};


export const getUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const behaviorRecord = (params, fun) => {
    // const headers = {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'X-HSBC-Request-Correlation-Id': getUUID(),
    //         'X-HSBC-CountryCode': 'HK'
    //     }
    // };

    // axios.post(
    //     process.env.REACT_APP_BEHAVIOR_RECORD_URL,
    //     params,
    //     headers).then(res => {
    //         console.log(res);
    //         if (res && res.data && res.data.responseData) {
    //             sessionStorage.setItem('stayTimeId', res.data.responseData);
    //         };
    //     }).
    //     catch(err => {
    //         console.log(typeof fun);
    //         if (typeof fun === 'function') {
    //             fun();
    //         }
    //         console.log(err);
    //     });
};

export const closePageBehaviorRecord = (params) => {
    // fetch(process.env.REACT_APP_BEHAVIOR_RECORD_URL, {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'X-HSBC-Request-Correlation-Id': getUUID(),
    //         'X-HSBC-CountryCode': 'HK'
    //     },
    //     body: JSON.stringify(params),
    //     keepalive: true
    // }).then((r) => {

    // });
};


export const buildStayTimeParams = (type) => {
    const firstOpenIndexParams = {
        customerId: getHashValueByHistory(),
        sourceType: (document.referrer && "TS") || '',
        sourceId: document.referrer,
        openIndexPageTime: getUKTime(),
        indexPageDwellTime: 0,
        openIndexPageFlag: 'Y'
    };
    const openSubmitParams = {
        id: "",
        customerId: getHashValueByHistory(),
        sourceType: (document.referrer && "TS") || '',
        indexPageDwellTime: 0,
        openSubmitPageTime: getUKTime(),
        openSubmitPageFlag: "Y",
        submitPageDwellTime: "0",
        formFillingTag: "N",
        submitFormFlag: "N"
    };
    const inputSubmitParams = {
        id: "",
        customerId: getHashValueByHistory(),
        sourceType: (document.referrer && "TS") || '',
        submitPageDwellTime: "0",
        formFillingTag: "Y",
        submitFormFlag: "N"
    };
    const submitedParams = {
        id: "",
        customerId: getHashValueByHistory(),
        sourceType: (document.referrer && "TS") || '',
        openSubmitPageFlag: "Y",
        submitPageDwellTime: 0,
        formFillingTag: "Y",
        submitFormFlag: "N"
    };

    const data = { firstOpenIndexParams, openSubmitParams, submitedParams, inputSubmitParams };
    return data[type];
};

export const saveTextToFile = (text, fileName) => {
    const blob = new Blob([text], { type: 'text/plain' });
    const a = document.createElement('a');
    const url = URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
};