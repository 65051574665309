import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';
import Cash_title from '../../assets/tradeShift_cash_title.svg';
import Cash_title_Blue from '../../assets/tradeShift_cash_title_blue.svg';
import phone from '../../assets/phone.jpg'
import top_arrow from '../../assets/top_arrow.svg'
import { INFORMATION_LIST, IS_VALID_PROSPECT_ID, IS_SET_BG_PURPLE } from '../../constant';
import {
	getUKTime, loadMeta, deleteMeta,
	getHashValueByHistory, behaviorRecord,
	buildStayTimeParams, saveTextToFile
} from '../../util';
import axios from 'axios';
import '../../style/index.css';


var isCalled = true;
export default function topSection() {
	const [isShowFooter, setIsShowFooter] = useState(false);
	const [resizeTo150, setResizeTo150] = useState(false);
	const [isShow, setIsShow] = useState(true);
	const [resizeInfo, setResizeInfo] = useState('important_information_a_right_212');
	const navigate = useNavigate();
	const storage = window.sessionStorage;

	const getStorage = () => {
		storage.getItem('stayIndexTime');
	};

	const setStorage = () => {
		storage.setItem('stayIndexTime', stayIndexTime);
	};

	const [stayIndexTime, setStayIndexTime] = useState(getStorage() || 0);

	const backToSubmit = async () => {
		const searchVal = getHashValueByHistory();
		let params = {
			...buildStayTimeParams('openSubmitParams'),
			indexPageDwellTime: stayIndexTime == 0 ? 1 : stayIndexTime,
			id: sessionStorage.getItem('stayTimeId')
		};
		behaviorRecord(params);
		if (searchVal) {
			navigate(`/uk/submit.html${window.location.search}`, { replace: false });
		} else {
			navigate(`/uk/submit.html`, { replace: false });
		};
	};

	const backToTop = () => {
		window.scroll(0, 0);
	};

	const openLink = (url) => {
		window.open(url, '_blank');
	};


	const setStyle = (element) => {
		for (let i = 0; i < element.length; i++) {
			let currentPixelRatio = window.devicePixelRatio;
			if (currentPixelRatio <= 1.5) {
				element[i].setAttribute('style', 'right: 212px !important');
			} else if (currentPixelRatio > 1.5 && currentPixelRatio <= 1.65) {
				element[i].setAttribute('style', 'right: 176px !important');
			} else if (currentPixelRatio > 1.65 && currentPixelRatio <= 1.875) {
				element[i].setAttribute('style', 'right: 105px !important');
			} else if (currentPixelRatio > 1.875 && currentPixelRatio <= 2.25) {
				element[i].setAttribute('style', 'right: 27px !important');
			} else if (currentPixelRatio > 2.25 && currentPixelRatio <= 2.625) {
				element[i].setAttribute('style', 'right: -27px !important');
			} else if (currentPixelRatio >= 2.625) {
				element[i].setAttribute('style', 'right: -35px !important');
			}
		}
	};

	window.addEventListener('scroll', () => {
		let scrollHeight =
			document.documentElement.scrollTop
			|| document.body.scrollTop;
		setIsShowFooter(parseInt(scrollHeight) > 760);
	});

	let lastPixelRatio = window.devicePixelRatio;
	const rezisePage = () => {
		let currentPixelRatio = window.devicePixelRatio;
		let alinkEle = document.getElementsByClassName('a_link');
		setStyle(alinkEle);

		lastPixelRatio = currentPixelRatio;

		const uls = document.getElementsByClassName('key_benefits_ul');
		for (let i = 0; i < uls.length; i++) {
			if (currentPixelRatio > 2.25) {
				uls[i].setAttribute('class', 'key_benefits_ul purple-bg-ul resize150_key_benefits_ul');
				for (let j = 0; j < uls[i].childNodes.length; j++) {
					uls[i].childNodes[j].setAttribute(
						'class',
						'resize150_key_benefits_li'
					)
				}
			} else {
				uls[i].setAttribute('class', 'key_benefits_ul purple-bg-ul');
				for (let j = 0; j < uls[i].childNodes.length; j++) {
					uls[i].childNodes[j].setAttribute(
						'class',
						''
					)
				}
			}
		};

		let scrollHeight =
			document.documentElement.scrollTop
			|| document.body.scrollTop;
		setIsShowFooter(parseInt(scrollHeight) > 760);

		try {
			if (currentPixelRatio < 1.5) {
				document.getElementById('first-section').style.marginLeft = '109px';
			} else if (currentPixelRatio > 1.5) {
				document.getElementById('first-section').style.marginLeft = '155px';
				document.getElementById('model').style.marginLeft = '155px';
				document.getElementById('model').style.marginRight = '0px';
				document.getElementById('model').style.top = '0px';
			} else {
				document.getElementById('first-section').style.marginLeft = '131px';
				document.getElementById('model').style.marginLeft = '0px';
				document.getElementById('model').style.marginRight = '100px';
				document.getElementById('model').style.top = '40px';
			}
		} catch (error) {

		}
	}

	window.onload = (() => {
		window.addEventListener('resize', function (e) {
			rezisePage();
		});
		rezisePage();
	});

	useEffect(() => {
		// if (document.location.host.indexOf('ws-whp-uat.business') !== -1) {
		// 	navigate(`/newHome.html`, { replace: false });
		// };
		if (IS_VALID_PROSPECT_ID) {
			sessionStorage.setItem('referrer', document.referrer);
			if (document.referrer) {
				if (document.referrer.indexOf(process.env.REACT_APP_DOMAIN_NAME) === -1) {
					setIsShow(false);
					return;
				};
			};

			if (window.location.search != '' && window.location.search != null) {
				const searchVal = getHashValueByHistory();
				if (searchVal.length == 18) {
					document.getElementById('index-page-root').style.display = 'block';
					behaviorRecord(buildStayTimeParams('firstOpenIndexParams'));
				} else {
					setIsShow(false);
				}
			} else {
				setIsShow(false);
			};
		} else {
			document.getElementById('index-page-root').style.display = 'block';
			behaviorRecord(buildStayTimeParams('firstOpenIndexParams'));
		}

		sessionStorage.setItem('referrer', document.referrer);
		rezisePage();
		let timeIndexId = setInterval(() => {
			_indexStayTime_ = _indexStayTime_ + 1;
			setStayIndexTime((Time) => Time + 1);
		}, 1000);

		return () => {
			clearInterval(timeIndexId);
			timeIndexId = null;
			_indexStayTime_ = 0;
			setStorage();
		};
	}, []);


	return (
		<div id="index-page-root" style={{ display: 'none' }}>
			<section className="ttr-container">
				<div className={`TopSection ${IS_SET_BG_PURPLE ? 'blue-bg' : ''}`}>
					<div className={`top-section purple-top-section ${IS_SET_BG_PURPLE ? 'blue-bg' : ''}`}>
						<div id='first-section' className="first-section">
							<img src={`${IS_SET_BG_PURPLE ? Cash_title_Blue : Cash_title}`} alt="Tradeshift Cash" />
							<h2 className={`${IS_SET_BG_PURPLE ? 'purple-font-white' : ''}`}>Get paid in hours, not months</h2>
						</div>
						<div id='model' className={`model ${IS_SET_BG_PURPLE ? 'purple-bg purple-model' : ''}`}>
							<div>Get in touch to find out how we can help unlock cash in your business</div>
							<button
								className={`${IS_SET_BG_PURPLE ? 'ttr-btn-white model-btn-white' : 'ttr-btn back-to-submit model-Request'}`}
								onClick={() => backToSubmit('topRequestACall')}>Request a
								call</button>
						</div>
					</div>
				</div>
				<div className="ttr-TopSection">
					<div className="ttr-search index">
						<div className="section1">
							<div className="Want-to-get">
								<div className="title">
									Want to get paid sooner for invoices with your customers
									on Tradeshift? With Tradeshift Cash, you could
									accelerate your cash flow with little effort.</div>
							</div>
							<div className="can-offer">We can offer a credit line of up to*</div>
							<div>
								<span className={`amount ${IS_SET_BG_PURPLE ? 'purple-bg-font' : ''}`}>300,000</span>
								<span className={`currency ${IS_SET_BG_PURPLE ? 'purple-bg-font' : ''}`}>GBP</span>
							</div>
							<div className="no-need">simply based on your credit score and the value of your unpaid invoices
							</div>
							<div className="and-above">Lending above £300,000 requires financial information and additional
								security provided through our simple, fast application process</div>
							<div className="credit-line">* Subject to HSBC credit approval</div>
							<div className="Find-out-how-btn"><button className={`amount ${IS_SET_BG_PURPLE ? 'ttr-btn-blue' : "ttr-btn back-to-submit"}`}
								onClick={() => backToSubmit('topFindOutHow')}>Find out how</button></div>
							<div className={`at-glance ${IS_SET_BG_PURPLE ? 'purple-bg purple-at-glance' : ''}`}>
								<h2>At a glance</h2>
								<ul>
									<li><i className={`${IS_SET_BG_PURPLE ? 'purple-Check' : 'Check'}`}></i>Cash on the same or next working day</li>
									<li><i className={`${IS_SET_BG_PURPLE ? 'purple-Check' : 'Check'}`}></i>Receive up to 90% of the value of your invoices</li>
									<li><i className={`${IS_SET_BG_PURPLE ? 'purple-Check' : 'Check'}`}></i>Single discounting charge; no additional charges</li>
									<li><i className={`${IS_SET_BG_PURPLE ? 'purple-Check' : 'Check'}`}></i>Just invoice in your Tradeshift platform as usual, no need to
										upload more information</li>
									<li><i className={`${IS_SET_BG_PURPLE ? 'purple-Check' : 'Check'}`}></i>All accessible within your Tradeshift platform</li>
								</ul>
							</div>
						</div>
						<div className="lase-section">
							<div className="key-benefits" id="key-benefits">
								<h2 className="vertical-line"><span>Key benefits</span></h2>
								<ul className={`key_benefits_ul ${resizeTo150 ? 'resize150_key_benefits_ul' : ''}`}>
									<li className={`${resizeTo150 ? 'resize150_key_benefits_li' : ''}`}>
										<p>Accelerated cash flow</p>
										<div>Receive early payment for your invoices within
											24 hours, without disrupting the relationship
											with your customer.</div>
									</li>
									<li className={`${resizeTo150 ? 'resize150_key_benefits_li' : ''}`}>
										<p>Painless financing</p>
										<div>Quick and simple access to credit in a way
											that works with how your business operates.</div>
									</li>
									<li className={`${resizeTo150 ? 'resize150_key_benefits_li' : ''}`}>
										<p>Complete control</p>
										<div>Predictable payments and transparent costs,
											making day-to-day cash flow easier to
											manage. </div>
									</li>
								</ul>
								<ul className={`key_benefits_ul ${resizeTo150 ? 'resize150_key_benefits_ul' : ''}`}>
									<li className={`${resizeTo150 ? 'resize150_key_benefits_li' : ''}`}>
										<p>Everything in one place</p>
										<div>Fully integrated with your invoicing workflow
											on Tradeshift, saving you time and effort.</div>
									</li>
									<li className={`${resizeTo150 ? 'resize150_key_benefits_li' : ''}`}>
										<p>Paperless process</p>
										<div>A hassle-free, set-and-forget approach where
											invoices are automaticity processed and
											converted into cash.</div>
									</li>
									<li className={`${resizeTo150 ? 'resize150_key_benefits_li' : ''}`}>
										<p>Backed by a trusted partner</p>
										<div>Finance provided by HSBC, one of the world's
											strongest banking partners and leading
											international trade bank, ensuring stability and
											security. </div>
									</li>
								</ul>
							</div>
							<div className="tradeshift-Cash-right">
								<h2 className="vertical-line"><span>Is Tradeshift Cash right for your business?</span></h2>
								<span>Tradeshift Cash could meet your needs if:</span>
								<div className={`at-glance ${IS_SET_BG_PURPLE ? 'purple-bg purple-at-glance' : ''}`}>
									<ul>
										<li><i className={`${IS_SET_BG_PURPLE ? 'purple-Check' : 'Check'}`}></i>You have money tied up in outstanding sales invoices that
											could be working harder for your business</li>
										<li><i className={`${IS_SET_BG_PURPLE ? 'purple-Check' : 'Check'}`}></i>Your sales are growing and you want to ensure that your
											cash flow will keep pace</li>
										<li><i className={`${IS_SET_BG_PURPLE ? 'purple-Check' : 'Check'}`}></i>You want to receive predictable cash flow and reduce the
											workload of chasing and managing late payments</li>
									</ul>
								</div><span>Our Tradeshift Cash solution could be suitable for businesses who:</span>
								<div className={`at-glance ${IS_SET_BG_PURPLE ? 'purple-bg purple-at-glance' : ''}`}>
									<ul>
										<li><i className={`${IS_SET_BG_PURPLE ? 'purple-Check' : 'Check'}`}></i>Sell to other businesses on credit terms</li>
										<li><i className={`${IS_SET_BG_PURPLE ? 'purple-Check' : 'Check'}`}></i>Sell directly to customers rather than acting as an agent
										</li>
										<li><i className={`${IS_SET_BG_PURPLE ? 'purple-Check' : 'Check'}`}></i>Invoice the customer on Tradeshift, upon completion of
											supply of product / service and avoid part invoicing</li>
										<li><i className={`${IS_SET_BG_PURPLE ? 'purple-Check' : 'Check'}`}></i>Have an actual or projected annual sales of £120k+ captured
											in Tradeshift</li>
										<li><i className={`${IS_SET_BG_PURPLE ? 'purple-Check' : 'Check'}`}></i>Have an actual or projected annual business turnover of
											£1m+</li>
									</ul>
								</div><span className="last-span">Tradeshift Cash is currently only available to select
									Tradeshift customers. You don't need to be an existing HSBC customer to apply.</span>
							</div>
							<div className="it_works">
								<h2 className="vertical-line"><span>How it works</span></h2><span
									className="t_c_releases">Tradeshift Cash releases cash from your invoices on Tradeshift
									within 24 hours. Here's a quick overview of how it works:</span>
								<ul>
									<li><span className={`circle ${IS_SET_BG_PURPLE ? 'purple-bg' : ''}`}>1<div></div></span><span className="heng"></span>
										<p>Issue invoice</p><span>You provide goods / services to the
											buyer and invoice them on
											Tradeshift as usual. HSBC
											automatically take care of the rest.</span>
									</li>
									<li><span className={`circle ${IS_SET_BG_PURPLE ? 'purple-bg' : ''}`}>2<div></div></span><span className="heng"></span>
										<p>Get paid</p><span>HSBC advance up to 90% of the
											invoices value and you receive cash
											in your business account within 24
											hours.</span>
									</li>
									<li><span className={`circle ${IS_SET_BG_PURPLE ? 'purple-bg' : ''}`}>3<div></div></span><span className="heng"></span>
										<p>Make repayment</p><span>You repay the balance to HSBC
											when funding falls due, in line
											with when your customer is
											expected to pay you.</span>
									</li>
									<li>
										<span className={`circle last_span ${IS_SET_BG_PURPLE ? 'purple-bg' : ''}`}>4<div></div></span>
										<p>Release limit</p><span>Your repayment frees up
											your limit to fund your next
											invoices.</span>
									</li>
								</ul>
							</div>
							<div className="get_started">
								<h2 className="vertical-line"><span>How to get started</span></h2>
								<div className={`get_started_flex ${IS_SET_BG_PURPLE ? 'purple-bg' : ''}`}>
									<div className={`at-glance ${IS_SET_BG_PURPLE ? 'purple-bg purple-at-glance' : ''}`}>
										<ul>
											<li>Connect with a business specialist</li>
											<li>Discuss a likely offer specific to your
												business
											</li>
											<li>Apply for Tradeshift Cash</li>
										</ul>
										<button className={`${IS_SET_BG_PURPLE ? 'purple-how-to-btn ttr-btn-white' : "ttr-btn request-btn"}`}
											onClick={() => backToSubmit('bottomRequestACall')}>Request a
											call</button>
									</div>
									<div>
										<img src={phone} alt="iphone" />
									</div>
								</div>
							</div>
						</div>
						<div className="back-to-top">
							<button className="back-btn">
								<a className={`index-circle ${IS_SET_BG_PURPLE ? 'purple-bg circle-bg' : ''}`} title="Back to top"
									onClick={backToTop}>
									<img src={top_arrow} alt="BackToTop" />
								</a>
								<a className="text" onClick={backToTop}>Back to top</a>
							</button>
						</div>
					</div>
				</div>
				<div className={`important_information ${IS_SET_BG_PURPLE ? 'purple-bg' : ''}`}>
					{
						INFORMATION_LIST.map(item => {
							return <a
								className={`a_link ${item.className}
							 	${resizeInfo}`} href={item.link}
								target="_blank" key={item.className}
								// onClick={(e) => { openLink(item.link) }}
							>
								{item.title}
							</a>
						})
					}
				</div>
				{isShowFooter ? <div className="fixed-bottom footer-section isShowFooter">
					<div className="get_in_touch">
						<div className="cta-panel-label___2BJxF"><span
							className="cta-panel-label__icon cta-panel-label__icon--product"></span>
							<div className="cta-panel-label__title___2mTiR">Get in touch to discuss how invoice financing
								could help you unlock cash</div>
						</div>
						<button className={`footer-jump-btn ${IS_SET_BG_PURPLE ? 'purple-footer-btn' : "footer-btn back-to-submit"}`} onClick={() => backToSubmit('bottomFindOutHow')}>Find out how</button>
					</div>
				</div> : null}
			</section>
		</div>
	);
}
